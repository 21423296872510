<template>
	<div class="confirmOrder" v-if="ifData">
		<!-- <div class="header-nav">
      <div class="header-nav-close">
        <icon name="cross" class="font-16" />
        <span>确认订单</span>
      </div>
      <div class="header-nav-right">
        <icon name="weapp-nav" />
      </div>
    </div>-->
		<div class="content">
			<notice-bar
				:scrollable="false"
				color="#000"
				background="#fff"
				class="content-hint"
				>您正在{{ OrderInfor.room_name }}加油</notice-bar
			>
			<div class="order-detils">
				<notice-bar
					:scrollable="false"
					color="#000"
					background="#fff"
					class="marginTop"
					>{{ staff }}加油员为您服务</notice-bar
				>
				<div class="order-details">
					<div class="order-details-lft">
						<img src="../../../public/static/images/vip/dianpuImg.png" alt />
						<div class="order-infor" v-if="selectedData">
							<span class="marginRgt">{{ selectedData.name }}号油枪</span>
							<span>{{ selectedData.goods.name }}</span>
						</div>
					</div>
					<div class="order-details-price">￥{{ price }}元</div>
				</div>

				<!-- <div class="member-hint">
        <div class="hint-header">
          <div class="hint-header-lft">
            <span class="iconfont iconhuiyuan2"></span>
            <span>油站会员</span>
          </div>
          <div class="hint-header-rgt">
            <span>开通会员送无门槛红包，可与减免/折扣同享；</span>
            <icon name="warning-o" />
          </div>
        </div>
        <div class="member-cont">
          <div class="member-cont-box">
            <div class="member-type">
              <tag color="#dea76d">金卡</tag>
            </div>
            <div class="member-open">
              <p style="font-weight:bold;font-size:14px;">
                送
                <span style="color:#ef563f">30元</span>红包，本单可用5元
              </p>
              <div class="btn">立即开通</div>
            </div>
          </div>
        </div>
        </div>-->

				<cell-group class="marginTop">
					<!--          <cell>-->
					<!--            <template slot="title">-->
					<!--              <span class="iconfont iconmanjian" style="color: red;"></span>-->
					<!--              <span class="custom-title">满减优惠</span>-->
					<!--            </template>-->
					<!--            <template slot="default">-->
					<!--              <span class="custom-title" style="color: red;font-weight:bold">-12</span>-->
					<!--            </template>-->
					<!--          </cell>-->
					<cell title="单元格" value="内容">
						<template slot="title">
							<span class="iconfont iconjifen3" style="color: red;"></span>
							<span class="custom-title">积分</span>
							<span style="color: #bdc2d7;"
								>(可用{{ Number(OrderInfor.wallet.point) }}分)</span
							>
						</template>

						<div slot="default" class="select-jifen">
							<span class="custom-title" style="color: red" v-if="checked"
								>可抵扣￥{{ deductIntegral }}</span
							>
							<checkbox
								v-model="checked"
								class="custom-title"
								@click="openMutex ? selectDiscounts('integral') : ''"
							></checkbox>
							<!-- :disabled="ifIntegral?false:true" -->
						</div>
					</cell>

					<!-- v-if="ifCoupon" -->
					<cell title="单元格" value="内容" is-link label="满减卷和优惠券同享">
						<template slot="title">
							<span class="iconfont iconyouhuijuan" style="color: red;"></span>
							<span class="custom-title">优惠券</span>
						</template>

						<div slot="default" class="select-jifen" @click="showCop = true">
							<span class="custom-title" style="color: red"
								>{{ bottom_ticket }}可用{{ is_used_num }}张</span
							>
						</div>
					</cell>

					<cell v-if="vip_status">
						<template slot="title">
							<span class="iconfont iconhuiyuan" style="color: red;"></span>
							<span class="custom-title">会员折扣</span>
						</template>
						<template slot="default">
							<span class="custom-title" style="color: red;font-weight:bold"
								>{{ OrderInfor.discount / 10 }}折</span
							>
						</template>
					</cell>

					<cell v-else>
						<template slot="title">
							<span class="iconfont iconhuiyuan" style="color: red;"></span>
							<span class="custom-title">未开通会员</span>
						</template>
					</cell>

					<cell>
						<!-- <template slot="title">
              <span class="custom-title">结算</span>
            </template>-->
						<template slot="default">
							<div class="order-price">
								已优惠
								<span>¥{{ discounts }}</span>
								小计￥
								<span>{{ pay_money }}</span>
							</div>
						</template>
					</cell>
				</cell-group>
			</div>

			<div class="pay-way">
				<div class="pay-title">
					<span>支付方式</span>
					<div>
						{{ receiptCont }}
						<Button
							type="primary"
							size="small"
							color="#1890ff"
							@click="openInvoice"
							v-if="radio != 1 && !receiptCont"
							>开发票</Button
						>
						<Button
							type="primary"
							size="small"
							color="#1890ff"
							@click="cancelInvoice"
							v-if="radio != 1 && receiptCont"
							>取消</Button
						>
					</div>
				</div>
				<radio-group v-model="radio">
					<cell-group>
						<div v-for="(item, index) in payType" :key="index">
							<cell
								clickable
								@click="radio = '1'"
								v-if="balanceMaybeSave && item.id == 2"
							>
								<template slot="title">
									<span class="iconfont iconyue" style="color: #ee6515;"></span>
									<span class="custom-title"
										>{{ item.name }}(剩余{{
											OrderInfor.wallet.balance
										}}元)</span
									>
								</template>
								<radio slot="right-icon" name="1" />
							</cell>
							<template v-else-if="!balanceMaybeSave && item.id == 2&&recharge_state">
								<cell clickable>
									<template slot="title">
										<span
											class="iconfont iconchuzhiqia"
											style="color: red;"
										></span>
										<span class="custom-title">储值支付</span>
									</template>
									<checkbox
										v-model="selectSave"
										@click="radio = '4'"
										style="justify-content: flex-end"
									></checkbox>
								</cell>
								<div class="stored-value">
									<Swipe
										:loop="false"
										:width="110"
										:height="56"
										:show-indicators="false"
										style="padding: 10px 0;"
									>
										<template v-for="(item, idx) in OrderInfor.recharge_set">
											<SwipeItem
												class="discounts-item"
												v-show="
													Number(item.amount) >= Number(pay_money)
														? true
														: false
												"
												:key="idx"
												@click="setSwiper(item, idx)"
											>
												<div
													:class="[
														swiperIdx == idx ? 'sw-active' : '',
														'discounts-item-con'
													]"
												>
													<Tag
														:type="swiperIdx == item ? 'success' : ''"
														class="discounts-item-con-check"
													>
														<span
															style="font-size:10px"
															class="iconfont iconduigou"
														></span>
													</Tag>
													<div>
														<span>冲</span>
														<span>{{ item.amount }}</span>
													</div>
													<p>送{{ item.balance }}元</p>
												</div>
											</SwipeItem>
										</template>
									</Swipe>
									<template v-if="giftsObject">
										<div class="stored-value-gifts">
											<div class="stored-value-gifts-opt">
												<p class="marginBtm">
													<span
														class="iconfont iconchuzhiqia"
														style="color:#ff9900"
													></span>
													&nbsp;储值到账{{
														Number(giftsObject.amount) +
															Number(giftsObject.balance)
													}}元
												</p>
												<p v-if="giftsObject.point" class="marginBtm">
													<span
														class="iconfont iconjifen3"
														style="color:#ff9900"
													></span>
													&nbsp;充值赠送{{ giftsObject.point }}积分
												</p>
												<template v-if="giftsObject.coupons.length > 0">
													<p>
														<span
															class="iconfont iconyouhuijuan"
															style="color:#ff9900"
														></span
														>&nbsp;充值送券
													</p>
													<p
														v-for="(item, index) in giftsObject.coupons"
														:key="index"
														class="coupon-option"
													>
														<span>{{ item.title }}</span
														>×1
													</p>
												</template>
											</div>
										</div>
										<div class="margin-Btm">
											充值后自动买单，买单后余额
											{{ savePrice }}
											元
										</div>
									</template>
								</div>
							</template>

							<template v-if="!selectSave">
								<cell clickable @click="radio = '2'" v-if="item.id == 4">
									<template slot="title">
										<span
											class="iconfont iconweixin1"
											style="color: #05c506;"
										></span>
										<span class="custom-title">{{ item.name }}</span>
									</template>
									<radio slot="right-icon" name="2" />
								</cell>
								<cell
									clickable
									@click="radio = '3'"
									v-if="isWeixin_status == false && item.id == 5"
								>
									<template slot="title">
										<span
											class="iconfont iconumidd17"
											style="color: #169ADD;"
										></span>
										<span class="custom-title">{{ item.name }}</span>
									</template>
									<radio slot="right-icon" name="3" />
								</cell>

								<cell clickable @click="radio = '5'" v-if="item.id == 7">
									<template slot="title">
										<span
											class="iconfont iconyinlian"
											style="color: #0b988f;"
										></span>
										<span class="custom-title">{{ item.name }}</span>
									</template>
									<radio slot="right-icon" name="5" />
								</cell>

								<cell clickable @click="radio = '6'" v-if="item.id == 3">
									<template slot="title">
										<span
											class="iconfont iconxianjin"
											style="color: #F5A844;"
										></span>
										<span class="custom-title">{{ item.name }}</span>
									</template>
									<radio slot="right-icon" name="6" />
								</cell>
							</template>
						</div>
					</cell-group>
				</radio-group>
			</div>
			<!--优惠券-->
			<Popup v-model="showCop" round position="bottom" style="height: 60%;">
				<div class="ticket-tab">
					<div
						:class="[now_tabIdx == idx ? 'active' : '']"
						v-for="(item, idx) in ticketType"
						:key="idx"
						:title="item"
						@click="now_tabIdx = idx"
					>
						{{ item }}
					</div>
				</div>
				<p class="hint youhui_hint">温馨提示: 以上三种优惠券不能同时使用</p>
				<div class="ticket-list">
					<RadioGroup v-model="now_ticketId">
						<div
							class="ticket-item"
							v-for="item in myCoupons"
							:key="item.id"
							@click="
								openMutex ? selectDiscounts('discounts', item) : set_radio(item)
							"
						>
							<!-- 如果互斥执行selectDiscounts('discounts') -->
							<div class="ticket-item-top">
								<div class="ticket-item-top-money">
									<!--满减券-->
									<h2 v-if="item.coupon_data.type == 1">
										{{ item.coupon_data.m_dec_money }}
										<span>元</span>
									</h2>
									<!--折扣券-->
									<h2 v-if="item.coupon_data.type == 2">
										{{ item.coupon_data.zhe_discount }}
										<span>折</span>
									</h2>
									<!--代金券-->
									<h2 v-if="item.coupon_data.type == 3">
										{{ item.coupon_data.d_face_value }}
										<span>元</span>
									</h2>
									<p>{{ item.coupon_data.sub_title }}</p>
								</div>
								<div class="ticket-item-top-name">
									<p>{{ item.coupon_data.title }}</p>
									<p>{{ item.exp_time }}</p>
									<div class="right-btn">
										<Radio :name="item.id"></Radio>
									</div>
								</div>
							</div>
							<div class="ticket-item-bottom">描述信息</div>
						</div>
					</RadioGroup>
				</div>
				<div class="nonuse-coupon">
					<Button type="danger" size="small" @click="nonuseCoupon" block
						>不使用优惠券</Button
					>
				</div>
			</Popup>
			<!-- 正常支付 -->
			<div class="pay-operation" v-if="!selectSave">
				<div class="pay-price">
					<p style="color: #fff;">
						￥
						<span class="price-font">{{ pay_money }}</span>
					</p>
					<span>已优惠￥{{ discounts }}</span>
				</div>
				<div class="operation">
					<!-- <div class="Other-pay">找人付</div> -->
					<div class="submit-pay" @click="submitOrder">提交订单</div>
				</div>
			</div>
			<!-- 储值支付 -->
			<div class="pay-operation" v-else>
				<div class="pay-price">
					<p style="color: #fff;">
						￥
						<span class="price-font">{{ Topuppayment }}</span>
					</p>
					<span>已优惠￥{{ discounts }}</span>
				</div>
				<div class="operation">
					<div class="Other-pay wx_pay" @click="czCreateOrder('single_wxpay')">
						微信支付
					</div>
					<div
						v-if="isWeixin_status == false"
						class="submit-pay zfb_pay"
						@click="czCreateOrder('single_alipay')"
					>
						支付宝支付
					</div>
				</div>
			</div>
		</div>

		<!-- 支付模块 -->
		<PayMoney
			v-if="payModel"
			ref="pay"
			:paytype="4"
			:money="String(pay_money)"
		></PayMoney>
	</div>
</template>

<script>
import PayMoney from "@/components/public/PayMoney";
import UserInfo from "@/api/user";
import Setting from "@/api/setting";
import CreditShop from "@/api/creditshop";
import {
	NoticeBar,
	Cell,
	CellGroup,
	Checkbox,
	Button,
	RadioGroup,
	Radio,
	Popup,
	SwipeItem,
	Swipe,
	Tag
} from "vant";

export default {
	components: {
		NoticeBar,
		Cell,
		CellGroup,
		Button,
		RadioGroup,
		Radio,
		Popup,
		PayMoney,
		SwipeItem,
		Swipe,
		Tag,
		Checkbox
	},
	data() {
		return {
			payType: [], // 支付方式
			selectSave: false, // 选中储值支付
			balanceMaybeSave: true, // true 余额，false储值 选择支付方式
			Topuppayment: "", //储值支付金额
			payClass: true, // 正常支付 false 储值只发
			selectidx: "", // 选择设备索引
			ifData: false,
			checked: false,
			radio: "0",
			selectedData: {},
			price: "", // 用户输入金额
			vip_status: false,
			my_cardInfor: {},
			pay_money: "", // 用户所见支付金额
			discounts: "",
			ticketType: ["满减券", "折扣券", "代金券"],
			showCop: false,
			now_tabIdx: 0,
			myCoupons: [],
			now_ticketId: "",
			// 优惠券
			payModel: true, // 支付
			staff: "站点", // 加油员名称
			OrderInfor: {},
			ifIntegral: false, //积分是否可用
			ifCoupon: false, //优惠券是否可用
			deductIntegral: 0, //积分优惠
			ruleAmount: 0, //一积分抵扣多少钱
			ruleRate: 0, //最多可以抵扣
			ruleMin: 0, //多少元起可以,最低多少元
			storeId: "", //店铺id
			now_ticketCon: "",
			bottom_ticket: `选择优惠券，`,
			ifselectYhj: false,
			upPrice: 0, // 上一次优惠券金额,
			room_id: "",
			ifStorage: false, // 是否存储支付
			giftsObject: "", // 赠送选项
			savePrice: "", //储值之后剩余金额
			swiperIdx: -1,
			newrecharge_id: "",
			openMutex: null, //是否开启互斥
			discountForce: "", //折扣力度
			PreferentialRecord: {}, //互斥时的优惠项
			is_used_num: 0,
			if_toLinkPay: false, // 是否使用银联支付
			return_url: "", //银联支付跳转链接
			receipt_id: "", //发票id
			receiptCont: "",
			recharge_state: true, // 未设置储值支付时不显示该选项
		};
	},
	watch: {
		checked(val) {
			if (val) {
				if (this.OrderInfor.point_set.allow_point == 1) {
					//判断活动是否开启
					if (Number(this.ruleMin) <= Number(this.price)) {
						// 判断是否够资格
						if (
							Number(this.OrderInfor.wallet.point * this.ruleAmount) >=
							Number((this.price * this.ruleRate) / 100)
						) {
							// 判断是否瞒住最大扣除 (能够抵扣的积分- 最大可以抵扣积分)
							this.pay_money =
								this.price * this.discountForce -
								(this.price * this.ruleRate) / 100 -
								this.upPrice;
							this.deductIntegral = (this.price * this.ruleRate) / 100; // 最大积分抵扣
						} else {
							this.pay_money =
								this.price * this.discountForce -
								this.OrderInfor.wallet.point * this.ruleAmount -
								this.upPrice;
							this.deductIntegral =
								this.OrderInfor.wallet.point * this.ruleAmount; // 最多可以积分抵扣
						}
					} else {
						this.$toast("您未满足条件");
						this.checked = false;
					}
				}
			} else {
				this.pay_money = this.pay_money + this.deductIntegral;
				console.log(this.pay_money);
				this.deductIntegral = 0;
				if (this.openMutex) {
					console.log(1212, this.PreferentialRecord);
					// this.set_radio(this.PreferentialRecord);
				}
			}
			this.discounts = Number(this.price - this.pay_money).toFixed(2);
		},
		now_tabIdx() {
			this.getCoupons();
		},
		radio(val) {
			switch (val) {
				case "4":
					this.ifStorage = true;
					// this.payClass = false;
					if (!this.Topuppayment) {
						this.Topuppayment = this.pay_money;
					}
					console.log(this.Topuppayment)
					break;
				default:
					this.ifStorage = false;
					// this.payClass = true;
					break;
			}
		},
		ifStorage(val) {
			if (!val) {
				this.giftsObject = "";
				this.radio = "0";
				this.swiperIdx = -1;
				this.Topuppayment = "";
			}
		},
		pay_money(val) {
			//如果余额大于付款金额为余额支付，否者是储值支付
			if (Number(this.OrderInfor.wallet.balance) >= Number(val)) {
				this.balanceMaybeSave = true;
			} else {
				this.balanceMaybeSave = false;
			}
		},
		selectSave(val) {
			//是否使用储值支付
			if (val == false) {
				this.ifStorage = false; // 不使用储值支付
			} else {
				this.radio = "4";
			}
		},
		discounts() {
			this.savePrice = (
				Number(this.OrderInfor.wallet.balance) +
				Number(this.giftsObject.amount) +
				Number(this.giftsObject.balance) -
				Number(this.pay_money)
			).toFixed(2);
		}
	},
	created() {
		this.selectidx = this.$route.query.selectindex;
		this.storeId = this.$route.query.storeId;
		this.room_id = this.$route.query.room_id;
		console.log(this.$route);
		this.price = this.$route.query.money;
		// this.pay_money = this.price;
		this.getPaytype();
		this.getoilType(this.storeId);
	},
	mounted() {
		this.isVip();
		this.getInvoiceInfor(); //判断是否是在选择发票页面返回的
	},
	methods: {
		async getPaytype() {
			// 获取支付方式
			try {
				const res = await CreditShop.getPaytype();
				this.payType = res.data;
				console.log(res);
			} catch (error) {
				console.log(error);
			}
		},
		nonuseCoupon() {
			// 取消使用优惠
			this.showCop = false;
			this.pay_money = Number(this.pay_money) + Number(this.upPrice);
			this.now_ticketId = "";
			this.now_ticketCon = "";
			this.bottom_ticket = "选择优惠券,";
			this.upPrice = 0;
			this.discounts = Number(this.price - this.pay_money).toFixed(2);
		},
		setSwiper(item, idx) {
			if (this.selectSave == false) {
				this.selectSave = true;
			}
			// this.now_paytype = "1"; // 余额充值
			this.recharge_id = item.id;
			this.swiperIdx = idx;
			this.giftsObject = item;
			this.savePrice = (
				Number(this.OrderInfor.wallet.balance) +
				Number(this.giftsObject.amount) +
				Number(this.giftsObject.balance) -
				Number(this.pay_money)
			).toFixed(2);
			console.log(item.amount)
			this.Topuppayment = Number(item.amount).toFixed(2);
			
		},
		czCreateOrder(type) {
			// 充值支付
			if (this.swiperIdx < 0) {
				//判断是否选择充值项
				this.$toast("请选择一项充值金额");
				this.recharge_id = "";
			} else {
				this.ewmCreateOrder(type);
			}
		},
		// 获取储值配置
		async getMoneyConf() {
			try {
				const res = await CreditShop.saveMoney();
				if (res.code == 200) {
					// 可使用优惠券数量
					console.log(res);
				}
			} catch (error) {
				console.log(error);
			}
		},
		async getUserInfo() {
			//用户信息
			try {
				const res = await Setting.GetInfo();
				if (res.code == 200) {
					this.my_cardInfor.user_info = res.data;
					console.log(this.my_cardInfor.user_info);
				}
			} catch (error) {
				console.log(error);
			}
		},
		// 确认订单
		submitOrder() {
			console.log(this.radio);
			this.recharge_id = "";
			this.if_toLinkPay = false; //不使用银联
			this.return_url = ""; // 回转链接
			switch (this.radio) {
				case "1":
					this.balancePay();
					break;
				case "2":
					this.ewmCreateOrder("single_wxpay");
					break;
				case "3":
					this.ewmCreateOrder("single_alipay");
					break;

				case "5":
					this.if_toLinkPay = true;
					// this.return_url = `http://cskf.58zltc.com/pay_success?appid=${localStorage.getItem(
					// 	"app_id"
					// )}&app_token=${localStorage.getItem("access_token")}&`;
					this.return_url = `http://cskf.58zltc.com/pay_success?appid=${localStorage.getItem("app_id")}&app_token=${localStorage.getItem("access_token")}&`
					this.ewmCreateOrder("unionpay");
					break;
				case "6": //现金支付
					this.ewmCreateOrder("cash");
					break;
			}
		},

		balancePay() {
			//提交订单 余额支付
			if (
				Number(this.my_cardInfor.user_info.wallet.balance) <
				Number(this.pay_money)
			) {
				this.$toast(
					`余额不足剩余${this.my_cardInfor.user_info.wallet.balance}`
				);
			} else {
				if (this.isPassword()) {
					this.$refs.pay.showPayToast();
				}
			}
		},
		// 判断是否设置交易密码
		async isPassword() {
			try {
				const res = await Setting.judgePayPass();
				if (res.code == 200) {
					return true;
				} else {
					this.$dialog
						.confirm({
							title: res.msg,
							message: "您暂未设置交易密码，无法使用余额支付，是否前往设置?"
						})
						.then(() => {
							// 设置交易密码判断类型
							sessionStorage.setItem("verifyType", "dealPassword");
							this.$router.push("/userSafety");
						})
						.catch(() => {
							throw res.msg;
						});
				}
			} catch (error) {
				this.$toast(error);
			}
		},
		// 余额付款
		async oilBalancePay(pwd) {
			try {
				// qrcode_id, cate_id, money, message, recharge, payment_code, point = "", recharge_id, pay_password = ""
				const res = await CreditShop.ewmCreateOrder(
					sessionStorage.getItem("qrcode_id"),
					// 73,
					this.room_id,
					this.selectedData.id,
					this.price,
					"",
					0,
					"balance",
					parseInt(this.deductIntegral / this.ruleAmount),
					pwd,
					"",
					this.now_ticketId
				);
				if (res.code == 200) {
					this.$toast(res.msg);
					this.$nextTick(() => {
						this.$router.push("/pay_success?ordersn=" + res.data.ordersn);
					});
					this.cancelInvoice();
				} else {
					throw res.msg;
				}
			} catch (error) {
				this.$toast(error);
			}
		},
		async ewmCreateOrder(type) {
			//提交订单 微信支付，支付宝支付
			try {
				const res = await CreditShop.ewmCreateOrder(
					sessionStorage.getItem("qrcode_id"),
					// 73,
					this.room_id,
					this.selectedData.id,
					this.price,
					"",
					this.ifStorage ? 1 : 0,
					type,
					parseInt(this.deductIntegral / this.ruleAmount),
					"",
					this.recharge_id,
					this.now_ticketId,
					this.return_url,
					this.receipt_id
				);
				if (res.code == 200) {
					console.log(res);
					this.cancelInvoice();
					if (this.if_toLinkPay) {
						//判断是否银联支付
						location.href = res.data.url;
					} else {
						if (this.radio == "6") {
							// 判断是否现金支付
							this.$dialog
								.alert({
									message: res.msg
								})
								.then(() => {
									this.$router.push("/vip_mycenter");
								});
						} else {
							this.$router.replace({
								path: "payewm",
								query: {
									imgUrl: res.data.qrcode,
									ordersn: res.data.ordersn,
									money: res.data.money,
									payType: type
								}
							});
						}
					}
				} else {
					throw res.msg;
				}
			} catch (error) {
				this.$toast(error);
			}
		},

		// 获取优惠券
		async getCoupons() {
			console.log(this.selectedData.goods);
			try {
				this.myCoupons = [];
				const res = await CreditShop.getAvailable(
					this.price,
					this.now_tabIdx + 1,
					2,
					this.room_id,
					this.selectedData.goods.id
				);
				if (res.code == 200) {
					// 可使用优惠券数量
					console.log(1212);
					if (res.data.length > 0) {
						console.log(1212);

						this.myCoupons = res.data;
					}
				} 
				this.is_used_num = res.num;
			} catch (error) {
				console.log(error);
			}
		},
		// 选择优惠券
		set_radio(item) {
			console.log(121212);
			// 点击显示单选按钮
			this.now_ticketId = item.id;
			this.now_ticketCon = item;
			this.ifselectYhj = true;
			this.pay_money = Number(this.pay_money) + Number(this.upPrice);
			switch (item.coupon_type) {
				// 满减券计算
				case 1:
					this.pay_money =
						(Number(this.price) - Number(item.coupon_data.m_dec_money)) * this.discountForce - Number(this.deductIntegral); // 最后支付金额 = (总金额-满减金额)*会员折扣-积分
					this.upPrice = item.coupon_data.m_dec_money;
					this.bottom_ticket = `使用满${item.coupon_data.m_enough_money}元，减${item.coupon_data.m_dec_money}元优惠券,`;
					break;
				// 折扣券计算
				case 2:
					if (item.coupon_data.zhe_limit == 1) { // 是否开启了优惠券最大折扣金额
						this.pay_money =
						(Number(this.price) - Number(item.coupon_data.zhe_limit_money)) *this.discountForce -Number(this.deductIntegral); // 最后支付金额 = (总金额-最大抵扣)*会员折扣-积分
						this.bottom_ticket = `使用${item.coupon_data.zhe_discount}折优惠券,`;
						this.$toast(`您使用的${item.coupon_data.zhe_discount}折优惠券,最大只能优惠${item.coupon_data.zhe_limit_money}元`)
						this.upPrice = Number(item.coupon_data.zhe_limit_money);
					} else {
						this.pay_money =
							((Number(this.price) * Number(item.coupon_data.zhe_discount)) /10) *this.discountForce -Number(this.deductIntegral); // 最后支付金额 = (总金额*优惠折扣)*会员折扣-积分
						this.bottom_ticket = `使用${item.coupon_data.zhe_discount}折优惠券,`;
						this.upPrice =
							Number(this.price) - (Number(this.price) * Number(item.coupon_data.zhe_discount)) / 10;
					}
					
					break;
				// 代金券
				case 3:
					this.pay_money =
						(Number(this.price) - Number(item.coupon_data.d_face_value)) * this.discountForce - Number(this.deductIntegral); // 最后支付金额 = (总金额-代金金额)*会员折扣-积分
					this.bottom_ticket = `使用${item.coupon_data.d_face_value}元代金券,`;
					this.upPrice = item.coupon_data.d_face_value;
					console.log(this.pay_money);
					break;
				default:
					break;
			}
			this.showCop = false;
			this.discounts = Number(this.price - this.pay_money).toFixed(2);
		},
		// 判断是否会员
		async isVip() {
			try {
				const res = await UserInfo.isVip();
				if (res.code == 200) {
					this.vip_status = Boolean(res.data);
					if (this.vip_status) {
						this.MygetVipList();
					} else {
						console.log(1212);
					}
				} else if (res.code == 400) {
					this.vip_status = Boolean(res.data);
					this.getUserInfo();
				}
			} catch (error) {
				console.log(error);
			}
		},
		// 获取我的会员卡信息
		async MygetVipList() {
			try {
				const res = await UserInfo.my_card();
				if (res.code == 200) {
					this.my_cardInfor = res.data;
					// this.pay_money = this.price * (res.data.card.discount / 100);
					// this.discounts = (this.price - this.pay_money).toFixed(2);
				}
			} catch (error) {
				console.log(error);
			}
		},
		// 获取配置信息 买单展示
		async getoilType(storeId) {
			try {
				const res = await CreditShop.offlineShow(
					sessionStorage.getItem("qrcode_id"),
					// 73,
					storeId,
					this.room_id
				);
				this.selectedData = res.data.goods[this.selectidx];
				this.ifData = true;
				this.OrderInfor = res.data;
				this.pay_money = Number(this.price * (res.data.discount / 100)).toFixed(
					2
				); //折扣信息
				this.discountForce = res.data.discount / 100;
				this.staff = res.data.title; //加油员信息
				this.discounts = (this.price - this.pay_money).toFixed(2); // 折扣后优惠金额
				// this.balanceMaybeSave =
				//   Number(this.OrderInfor.wallet.balance) > Number(this.pay_money) ? true : false;
				if (!res.data.recharge_set||res.data.recharge_set.length==0) {
					this.recharge_state = false;
				} else {
					this.recharge_state = true;
				}
				if (res.data.preferce_set) {
					this.judgeRule(res.data.preferce_set.offline_buy);
				} else {
					throw "暂未设置互斥规则";
				}
				this.getCoupons(); // 获取优惠
			} catch (error) {
				console.log(error);
			}
		},
		judgeRule(arr) {
			// 判断规则
			let Rule = arr.split(",");
			console.log(Rule);
			this.ruleAmount = this.OrderInfor.point_set.rule.amount;
			this.ruleRate = this.OrderInfor.point_set.rule.rate;
			this.ruleMin = this.OrderInfor.point_set.rule.min;
			if (Rule.indexOf("1") != -1 && Rule.indexOf("2") != -1) {
				this.openMutex = true; //开启互斥
			} else {
				this.openMutex = false; //关闭互斥
			}
		},
		selectDiscounts(type, item = "") {
			// this.$toast("您只能使用一种优惠方式");
			if (type == "discounts") {
				if (this.checked == true) {
					this.set_radio(item);
					this.checked = false;
				}
				this.set_radio(item);
				this.PreferentialRecord = item;
				console.log(1212);
			} else {
				console.log(2323);
				this.pay_money = Number(this.pay_money) + Number(this.upPrice);
				this.now_ticketId = "";
				this.now_ticketCon = "";
				this.bottom_ticket = "选择优惠券,";
				this.upPrice = 0;
			}
		},
		// 开发票
		openInvoice() {
			this.$router.push({
				path: "/invoice_mag",
				query: {
					source: "order"
				}
			});
		},
		cancelInvoice() {
			this.receipt_id = "";
			this.receiptCont = "";
			this.$store.commit("setInvoiceId", "");
		},
		async getInvoiceInfor() {
			try {
				if (this.$store.state.user.invoiceId) {
					const res = await CreditShop.invoiceIdDetail(
						this.$store.state.user.invoiceId
					);
					this.receipt_id = res.data.id;
					this.receiptCont = `发票抬头-${res.data.tax_name}`;
				}
			} catch (error) {
				console.log(error);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.confirmOrder {
	background: #ededed;
	// min-height: 667px;
	height: 100vh;
	position: relative;
	overflow: auto;

	.header-nav {
		background: #ededed;
		width: 100%;
		height: 45px;
		padding: 0 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 15px;
		font-weight: bold;
		position: fixed;
		top: 0;

		.header-nav-close {
			display: flex;
			align-items: center;

			.font-16 {
				font-size: 18px;
				margin-right: 8px;
			}
		}
	}

	.content {
		padding: 10px 12px;
		padding-bottom: 80px;
		// position: absolute;
		// top: 45px;
		// width: 100%;
		// height: calc(100vh - 45px);
		// overflow: auto;

		.order-detils {
			margin-top: 10px;
			border-radius: 8px;
			overflow: hidden;
		}

		.content-hint {
			height: 30px;
			border-radius: 5px;
			color: #5474ec !important;
			border: 1px solid rgba(72, 116, 244, 1);
			background: rgba(84, 116, 236, 0.19) !important;
		}

		.order-details {
			padding: 5px 15px 5px 16px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			background: #fff;
			border-top: 1px solid #ededed;

			.order-details-price {
				font-size: 26px;
				// color: rgb(238, 8, 8);
				color: #000000;
			}

			.order-details-lft {
				color: #da0808;
				font-size: 14px;
				display: flex;

				.order-infor {
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					color: #000000;
					margin-left: 10px;
				}

				.marginRgt {
					margin-right: 8px;
				}
			}
		}

		.member-hint {
			width: 96%;
			background: #fff;
			border-radius: 10px;
			overflow: hidden;
			min-height: 130px;
			margin: 0 auto;
			margin-top: 10px;

			.hint-header {
				background: #181e1e;
				height: 30px;
				line-height: 30px;
				display: flex;
				align-items: flex-end;

				.hint-header-lft {
					color: #ecc372;
					width: 25%;
					display: flex;
					padding: 0 5px;
					justify-content: space-around;
				}

				.hint-header-rgt {
					flex: 1;
					height: 26px;
					background: #fff;
					border-top-left-radius: 8px;
					border-top-right-radius: 8px;
					text-align: center;
					line-height: 26px;
				}
			}
		}

		.member-cont {
			padding: 10px;
			height: 100px;

			.member-cont-box {
				background: #fafafa;
				height: 80px;
				border-radius: 8px;
				padding: 14px;
				display: flex;
				flex-direction: column;
				justify-content: space-around;

				.member-open {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.btn {
						padding: 4px 8px;
						background: #1890ff;
						border-radius: 5px;
						color: #ffffff;
					}
				}
			}
		}

		.custom-title {
			margin-left: 5px;
		}

		.order-price {
			font-size: 13px;
			font-weight: 400;
			color: rgba(0, 0, 0, 1);
			text-align: right;

			span:nth-child(1) {
				color: #ea2400;
				margin-right: 15px;
			}

			span:nth-child(2) {
				font-size: 20px;
			}
		}

		.select-jifen {
			display: flex;
			justify-content: flex-end;
		}

		.pay-way {
			margin-top: 10px;
			border-radius: 8px;
			overflow: hidden;

			.stored-value {
				// height: 70px;
				padding: 0 10px;
				.discounts-item {
					padding: 0 10px;

					.discounts-item-con {
						width: 100%;
						height: 100%;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: space-between;
						border-radius: 3px;
						padding: 6px 10px;
						background: #fdfdfd;
						border: 1px solid #dfdfdf;

						.discounts-item-con-check {
							position: absolute;
							left: 10px;
							top: 0;
						}
					}

					.sw-active {
						color: rgba(0, 149, 255, 1);
						background: rgba(238, 245, 255, 1);
						border: 1px solid rgba(0, 149, 255, 1);
					}
				}
				.stored-value-gifts {
					height: 100px;
					background: antiquewhite;
					margin-bottom: 10px;
					padding: 10px;
					font-size: 12px;
					.stored-value-gifts-opt {
						width: 100%;
						height: 100%;
						overflow: auto;
					}
					span {
						margin-right: 5px;
						font-size: 12px;
					}
					.marginBtm {
						margin-bottom: 3px;
					}
					.coupon-option {
						padding-left: 20px;
					}
				}

				.margin-Btm {
					padding-bottom: 8px;
				}
				.submitPay {
					height: 50px;
					display: flex;
					.paymony {
						flex: 2;
						text-align: center;
						.mony {
							font-size: 18px;
						}
					}
					.submitStored {
						flex: 8;
						display: flex;
						span {
							margin-right: 5px;
						}
						.wxpay {
							flex: 1;
							margin-right: 5px;
						}
						.zfbpay {
							flex: 1;
						}
					}
				}
			}
		}

		.pay-title {
			padding: 8px 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: #fff;
		}

		.pay-operation {
			width: 350px;
			height: 50px;
			border-radius: 25px;
			// margin: 15px auto 25px;
			// background: #191919;
			overflow: hidden;
			position: fixed;
			bottom: 10px;
			left: 50%;
			margin-left: -175px;
		}

		.pay-operation {
			display: flex;
			justify-content: space-between;
			background: #333335;

			.pay-price {
				color: #717172;
				padding: 5px 15px;
				display: flex;
				flex-direction: column;
				justify-content: space-around;

				.price-font {
					color: #fff;
					font-size: 18px;
					font-weight: bold;
				}
			}

			.operation {
				display: flex;

				div {
					width: 80px;
					height: 100%;
					line-height: 50px;
					text-align: center;
					font-size: 15px;
					font-weight: bold;
				}

				.submit-pay {
					background: linear-gradient(
						139deg,
						rgba(124, 163, 249, 1) 0%,
						rgba(70, 106, 240, 1) 100%
					);
					color: #fff;
				}

				.Other-pay {
					background: #484848;
					color: #fff;
				}

				.zfb_pay {
					width: 100px;
					background: #199bdd;
				}

				.wx_pay {
					width: 100px;
					background: #05c506;
				}
			}
		}

		.ticket-tab {
			width: 100%;
			position: fixed;
			display: flex;
			user-select: none;
			background-color: #fff;
			border-bottom: 1px solid #dfdfdf;
			border-radius: 20px 20px 0 0;
			z-index: 200;

			div,
			.active {
				position: relative;
				flex: 1;
				box-sizing: border-box;
				min-width: 0;
				padding: 0 5px;
				color: #646566;
				font-size: 14px;
				line-height: 44px;
				text-align: center;
				cursor: pointer;
			}

			.active {
				color: #ee0a24;
			}
		}

		.youhui_hint {
			margin-top: 45px;
			text-align: center;
		}

		.nonuse-coupon {
			height: 40px;
			position: absolute;
			bottom: 0;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 0 30px;
		}

		.ticket-list {
			width: 100%;
			height: 320px;
			padding: 20px 10px;
			display: flex;
			flex-direction: column;
			align-items: center;
			overflow: auto;
			padding-top: 5px;

			.ticket-item {
				width: 96%;
				height: auto;
				border-radius: 8px;
				box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
				display: flex;
				overflow: hidden;
				margin-top: 10px;
				padding-top: 14px;
				flex-direction: column;
				justify-content: space-between;

				.ticket-item-top {
					width: 100%;
					min-height: 84px;
					border-bottom: 1px dashed #ebedf0;
					display: flex;
					padding: 6px 10px;
					justify-content: space-between;

					.ticket-item-top-money {
						position: relative;
						min-width: 96px;
						padding: 0 8px;
						color: #ee0a24;
						text-align: center;
						color: #ee0a24;

						h2 {
							margin-bottom: 6px;
							font-weight: 500;
							font-size: 16px;
							overflow: hidden;

							span {
								font-weight: normal;
								font-size: 60%;
							}
						}

						p {
							text-overflow: ellipsis;
							overflow: hidden;
							font-size: 12px;
							line-height: 16px;
							white-space: pre-wrap;
						}
					}

					.ticket-item-top-name {
						flex: 1;
						min-width: 220px;
						height: 100%;
						position: relative;

						p:nth-child(1) {
							font-weight: bold;
							font-size: 14px;
							line-height: 20px;
						}

						p {
							margin-bottom: 10px;
						}

						.right-btn {
							position: absolute;
							top: 0;
							right: 16px;
							bottom: 0;
							display: flex;
							align-items: center;
						}
					}
				}

				.ticket-item-bottom {
					padding: 6px;
				}
			}

			.bottom-text {
				width: 100%;
				height: 25px;
				position: fixed;
				text-align: center;
				line-height: 25px;
				font-size: 14px;
				left: 0;
				color: #000000;
				bottom: 0;
				background: #ecf0f1;
			}
		}
	}
}
</style>
